.transactions {
  @media (max-width: 1650px) {
    padding-right: 15px;
    position: absolute;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    top: 102px;
    left: 121px;
    font-size: 22px;
    width: 416px;
    height: 657px;
    overflow-y: scroll;
  }
  @media (min-width: 1650px) {
    padding-right: 15px;
    position: absolute;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    top: 217px;
    left: -5px;
    font-size: 25px;
    width: 650px;
    height: 439px;
    overflow-y: scroll;
    transform: rotate(90deg);
  }
  .transaction-container {
    transform: translate(0, -3px);
    box-sizing: border-box;
    border-top: white solid 1px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 5px;
    .transaction-information {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .description {
        font-weight: lighter;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 80%;
      }
    }
    .date {
    }
  }
  .green {
    color: green;
  }
  .red {
    color: red;
  }
}


::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #777b7e;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d8be8a;
  border-radius: 5px;
  width: 5px;
}