.login-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  animation: flyIn 1s ease-in-out;
  transition: all 2s ease-in-out ;
  transform: scale(0.7);
  .left-container {
    //background: #2c2c2c;
    background: #000000;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    overflow: hidden;
    animation-delay: 0.02s;
    .logo {
      min-height: 10vw;
      width: 100%;
      //background: url("../../assets/logo-long.png");
      //background-size: cover;
      img {
        height: auto;
        width: 80%;
      }
    }
    .text-container {
      font-family: Credit, serif;
      position: absolute;
      inset: 0 0 0 0;
      padding-left: 30px;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      div {
        text-align: left;
        width: 100%;
      }
    }
    .text {
      &-big {
        font-size: 50px;
        color: white;
      }
      &-small {
        font-size: 24px;
        margin-top: 100px;
        color: white;
        font-weight: 100;
      }
    }
    .golden {
      color: #d8be8a;
      font-weight: bold;
    }
  }
  .right-container {
    width: 60%;
    position: relative;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 800px) {
      width: 100%;
    }
    @media (min-width: 800px) {
      justify-content: center;
    }
    .form-container {
      position: absolute;
      background: rgb(255, 255, 255);
      border-radius: 20px;
      transition: all 2s ease-in-out;
      @media (max-width: 800px) {
        position: relative;
        max-width: min(90%, 400px);
        background: transparent;
        border: none;
        backdrop-filter: none;
      }
      @media (min-width: 800px) {
        padding: 40px 60px;
        width: 600px ;
        justify-content: flex-start;
      }
      .form-content {
        text-align: center;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        @media (max-width: 800px) {
          min-width: 100%;
          background: transparent;
          border: none;
          backdrop-filter: none;
        }
        @media (min-width: 800px) and (max-width: 1200px) {
          width: 400px;
        }
        @media (min-width: 1200px) {
          width: 500px;
        }
        .label {
          display: none;
          @media (min-width: 800px) {
            display: block;
            font-size: 20px;
            font-weight: 100;
            position: absolute;
            text-transform: uppercase;
          }
        }
        .login.label {
          margin-left: 5px;
          left: 0;
          top: 50%;
          writing-mode: vertical-lr;
          transform: translateY(-50%);
        }
        .register.label {
          margin-right: 5px;
          left: 0;
          top: 50%;
          writing-mode: vertical-lr;
          transform: rotate(180deg) translateY(50%);
        }
      }
      .form-header {
        margin-top: 20px;
        height: 60px;
        font-size: 30px;
        //color: #3b3b3b;
        color: white;
        font-weight: 400;
        background: #000000;
        padding: 5px 80px;
        //border-radius: 20px;
        white-space: nowrap;
        display: none;
        @media (max-width: 800px) {
        }
      }
      .logo-container {
          user-select: none;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            height: 80px;
          }
      }
      .form-information {
        user-select: none;
        font-size: 24px;
        margin: 40px 0;
        color: #3b3b3b;
        font-weight: 100;
      }
      .red {
        color: #ff0000;
        font-weight: 300;
      }
      .input-container {
        position: relative;
        width: 100%;
        display: flex;
        .input {
          height: 50px;
          width: 100%;
          margin-bottom: 20px;
          border-radius: 15px;
          border: 1px solid #b7b7b7;
          padding-left: 70px;
          font-size: 24px;
          font-weight: 100;
          &:focus {
            background: #f5f5f5;
            border: 1px solid #3b3b3b;
            outline: none;
          }
        }
        .image {
          position: absolute;
          height: 40px;
          width: 40px;
          background-size: cover;
        }
        .head {
          left: 8px;
          top: 8px;
          opacity: 0.6;
          background: url('../../assets/user.svg');
        }
        .key {
          left: 8px;
          top: 8px;
          opacity: 0.6;
          background: url('../../assets/key.svg');
        }
        .phone {
          left: 8px;
          top: 8px;
          opacity: 0.6;
          background: url('../../assets/phone.svg');
          background-size: cover;
        }
        .eye {
          cursor: pointer;
          opacity: 0.6;
          right: 8px;
          top: 8px;
        }
        .closed {
          background: url('../../assets/eye_closed.svg');
          background-size: cover;
        }
        .open {
          background: url('../../assets/eye_open.svg');
          background-size: cover;
        }
      }
      .login-button {
        user-select: none;
        font-family: Credit, serif;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 60px;
        font-size: 25px;
        //color: #3b3b3b;
        color: #3b3b3b;
        font-weight: 800;
        background: #d6bd89;
        padding: 5px 30px;
        margin-top: 15px;
        border: 1px solid #3b3b3b;
        //border-radius: 20px;
        transition: all 0.4s ease-in-out;
        &:hover {
          color: #d6bd89;
          background: #3b3b3b;
          border: 3px solid #d6bd89;
        }
      }
      .activate {
        @media (min-width: 800px) {
          display: none;
        }
        text-decoration: underline;
        margin-top: 40px;
        font-size: 15px;
        cursor: pointer;
      }
    }
  }
  .separator {
    position: relative;
    height: 100%;
    width: 600px;
    overflow: hidden;
    //background: url("../../assets/peaks.svg");
    background: url("../../assets/waves.svg");
    background-size: cover;
  }
}

.packaging {
  transition: 2.2s ease-in-out all;
  transform: translateX(0);
  width: 60%;
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  @media (max-width: 800px) {
    width: 100%;
  }
  @media (min-width: 800px) {
    max-width: 800px;
    height: 600px;
    z-index: 1;
    //transform: translateX(400px);
  }
  .background-packaging {
    cursor: pointer;
    @media (min-width: 800px) {
      position: absolute;
      overflow: hidden;
      height: 100%;
      width: 100%; // register_disabled
      //background: black;
      border-radius: 50%;
      z-index: 1;
      .hole {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        position: absolute;
        top: 50%;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        box-shadow: 0 0 0 99999px rgb(0, 0, 0);
        .image {
          position: absolute;
          transition: all linear 1s;
          animation: wiggle infinite 1s linear;
        }
        .right-arrow.image {
          height: 70px;
          width: 70px;
        }
        .left-arrow.image {
          height: 70px;
          width: 70px;
        }
        .arrow {
          opacity: 0.6;
          background: url('../../assets/arrow.svg');
          background-size: cover;
        }
        .right-arrow.arrow {
        }
        .left-arrow.arrow {
          transform: translateX(100%) rotate(180deg);
        }

      }
      .left {
        transform: translate(-50%, -50%);
      }
      .right {
        transform: translate(+50%, -50%);
      }
    }
  }
  .left {
    border-radius: 10px; // register_disabled
    left: 0;
  }
  .right {
    border-radius: 0 10px 10px 0;
    right: 0;
  }
  .zeniq-logo-packaging {
    user-select: none;
    display: none;
    pointer-events: none;
    @media (min-width: 800px) {
      display: flex;
    }
    position: absolute;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    z-index: 2;
    img {
      height: 200px;
    }
  }
}


.slideOutLeft {
  animation: slideOutLeft 2s ease-in-out forwards;
}

.left.slideOutRight {
  animation: slideOutRight 2s ease-in-out forwards;
}
.right.slideOutRight {
  animation: slideOutLeft 2s ease-in-out forwards;
}
@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200vw);
  }
}
@keyframes slideOutRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(200vw);
  }
}


.login {
  @media (min-width: 800px) {
    left: 0;
  }
}
.login.extend {
  @media (min-width: 800px) and (max-width: 1200px) {
    left: -500px !important;
    width: 800px !important;
  }
  @media (min-width: 1200px) {
    left: -700px !important;
    width: 800px !important;
  }
}

.register {
  @media (min-width: 800px) {
    display: flex;
    justify-content: flex-end !important;
    align-items: center;
    right: 0;
  }
}
.register.extend {
  @media (min-width: 800px) and (max-width: 1200px) {
    right: -500px !important;
    width: 800px !important;
  }
  @media (min-width: 1200px) {
    right: -700px !important;
    width: 800px !important;
  }
}

.mobile-show {
  @media (max-width: 800px) {
    display: flex !important;
  }
}
.mobile-hide {
  @media (max-width: 800px) {
    display: none !important;
  }
}

.wiggle-left {
  @media (min-width: 800px) {
    animation: linear 1.5s wiggleLeft infinite;
  }
}

.wiggle-right {
  @media (min-width: 800px) {
    animation: linear 1.5s wiggleRight infinite;
  }
}
.left-packaging {
  @media (min-width: 800px) {
    transform: translateX(500px);
  }
}
.right-packaging {
  @media (min-width: 800px) {
    //transform: translateX(-500px); // register disabled
  }
}


@keyframes wiggle {
  0% {
    left: 0;
  }
  25% {
    left: 10px;
  }
  50% {
    left: 0;
  }
  75% {
    left: -10px;
  }
  100% {
    left: 0;
  }
}

@keyframes wiggleLeft {
  0% {
    left: 0;
  }
  25% {
    left: 30px;
  }
  50% {
    left: 0;
  }
  75% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}

@keyframes wiggleRight {
  0% {
    right: 0;
  }
  25% {
    right: -30px;
  }
  50% {
    right: 0;
  }
  75% {
    right: 30px;
  }
  100% {
    right: 0;
  }
}

@keyframes flyIn {
  0% {
    transform: scale(1) translateY(-200vh);
  }
  80% {
    transform: scale(1) translateY(0);
  }
  100% {
    transform: scale(0.7) translateY(0);
  }
}

