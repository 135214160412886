.login-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  animation: flyIn 1s ease-in-out;
  transition: all 2s ease-in-out;
  transform: scale(0.7);
}
.login-container .left-container {
  background: #000000;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow: hidden;
  animation-delay: 0.02s;
}
.login-container .left-container .logo {
  min-height: 10vw;
  width: 100%;
}
.login-container .left-container .logo img {
  height: auto;
  width: 80%;
}
.login-container .left-container .text-container {
  font-family: Credit, serif;
  position: absolute;
  inset: 0 0 0 0;
  padding-left: 30px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.login-container .left-container .text-container div {
  text-align: left;
  width: 100%;
}
.login-container .left-container .text-big {
  font-size: 50px;
  color: white;
}
.login-container .left-container .text-small {
  font-size: 24px;
  margin-top: 100px;
  color: white;
  font-weight: 100;
}
.login-container .left-container .golden {
  color: #d8be8a;
  font-weight: bold;
}
.login-container .right-container {
  width: 60%;
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 800px) {
  .login-container .right-container {
    width: 100%;
  }
}
@media (min-width: 800px) {
  .login-container .right-container {
    justify-content: center;
  }
}
.login-container .right-container .form-container {
  position: absolute;
  background: rgb(255, 255, 255);
  border-radius: 20px;
  transition: all 2s ease-in-out;
}
@media (max-width: 800px) {
  .login-container .right-container .form-container {
    position: relative;
    max-width: min(90%, 400px);
    background: transparent;
    border: none;
    backdrop-filter: none;
  }
}
@media (min-width: 800px) {
  .login-container .right-container .form-container {
    padding: 40px 60px;
    width: 600px;
    justify-content: flex-start;
  }
}
.login-container .right-container .form-container .form-content {
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 800px) {
  .login-container .right-container .form-container .form-content {
    min-width: 100%;
    background: transparent;
    border: none;
    backdrop-filter: none;
  }
}
@media (min-width: 800px) and (max-width: 1200px) {
  .login-container .right-container .form-container .form-content {
    width: 400px;
  }
}
@media (min-width: 1200px) {
  .login-container .right-container .form-container .form-content {
    width: 500px;
  }
}
.login-container .right-container .form-container .form-content .label {
  display: none;
}
@media (min-width: 800px) {
  .login-container .right-container .form-container .form-content .label {
    display: block;
    font-size: 20px;
    font-weight: 100;
    position: absolute;
    text-transform: uppercase;
  }
}
.login-container .right-container .form-container .form-content .login.label {
  margin-left: 5px;
  left: 0;
  top: 50%;
  writing-mode: vertical-lr;
  transform: translateY(-50%);
}
.login-container .right-container .form-container .form-content .register.label {
  margin-right: 5px;
  left: 0;
  top: 50%;
  writing-mode: vertical-lr;
  transform: rotate(180deg) translateY(50%);
}
.login-container .right-container .form-container .form-header {
  margin-top: 20px;
  height: 60px;
  font-size: 30px;
  color: white;
  font-weight: 400;
  background: #000000;
  padding: 5px 80px;
  white-space: nowrap;
  display: none;
}
.login-container .right-container .form-container .logo-container {
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-container .right-container .form-container .logo-container img {
  height: 80px;
}
.login-container .right-container .form-container .form-information {
  user-select: none;
  font-size: 24px;
  margin: 40px 0;
  color: #3b3b3b;
  font-weight: 100;
}
.login-container .right-container .form-container .red {
  color: #ff0000;
  font-weight: 300;
}
.login-container .right-container .form-container .input-container {
  position: relative;
  width: 100%;
  display: flex;
}
.login-container .right-container .form-container .input-container .input {
  height: 50px;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 15px;
  border: 1px solid #b7b7b7;
  padding-left: 70px;
  font-size: 24px;
  font-weight: 100;
}
.login-container .right-container .form-container .input-container .input:focus {
  background: #f5f5f5;
  border: 1px solid #3b3b3b;
  outline: none;
}
.login-container .right-container .form-container .input-container .image {
  position: absolute;
  height: 40px;
  width: 40px;
  background-size: cover;
}
.login-container .right-container .form-container .input-container .head {
  left: 8px;
  top: 8px;
  opacity: 0.6;
  background: url("../../assets/user.svg");
}
.login-container .right-container .form-container .input-container .key {
  left: 8px;
  top: 8px;
  opacity: 0.6;
  background: url("../../assets/key.svg");
}
.login-container .right-container .form-container .input-container .phone {
  left: 8px;
  top: 8px;
  opacity: 0.6;
  background: url("../../assets/phone.svg");
  background-size: cover;
}
.login-container .right-container .form-container .input-container .eye {
  cursor: pointer;
  opacity: 0.6;
  right: 8px;
  top: 8px;
}
.login-container .right-container .form-container .input-container .closed {
  background: url("../../assets/eye_closed.svg");
  background-size: cover;
}
.login-container .right-container .form-container .input-container .open {
  background: url("../../assets/eye_open.svg");
  background-size: cover;
}
.login-container .right-container .form-container .login-button {
  user-select: none;
  font-family: Credit, serif;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 60px;
  font-size: 25px;
  color: #3b3b3b;
  font-weight: 800;
  background: #d6bd89;
  padding: 5px 30px;
  margin-top: 15px;
  border: 1px solid #3b3b3b;
  transition: all 0.4s ease-in-out;
}
.login-container .right-container .form-container .login-button:hover {
  color: #d6bd89;
  background: #3b3b3b;
  border: 3px solid #d6bd89;
}
.login-container .right-container .form-container .activate {
  text-decoration: underline;
  margin-top: 40px;
  font-size: 15px;
  cursor: pointer;
}
@media (min-width: 800px) {
  .login-container .right-container .form-container .activate {
    display: none;
  }
}
.login-container .separator {
  position: relative;
  height: 100%;
  width: 600px;
  overflow: hidden;
  background: url("../../assets/waves.svg");
  background-size: cover;
}

.packaging {
  transition: 2.2s ease-in-out all;
  transform: translateX(0);
  width: 60%;
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
@media (max-width: 800px) {
  .packaging {
    width: 100%;
  }
}
@media (min-width: 800px) {
  .packaging {
    max-width: 800px;
    height: 600px;
    z-index: 1;
  }
}
.packaging .background-packaging {
  cursor: pointer;
}
@media (min-width: 800px) {
  .packaging .background-packaging {
    position: absolute;
    overflow: hidden;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    z-index: 1;
  }
  .packaging .background-packaging .hole {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: 0 0 0 99999px rgb(0, 0, 0);
  }
  .packaging .background-packaging .hole .image {
    position: absolute;
    transition: all linear 1s;
    animation: wiggle infinite 1s linear;
  }
  .packaging .background-packaging .hole .right-arrow.image {
    height: 70px;
    width: 70px;
  }
  .packaging .background-packaging .hole .left-arrow.image {
    height: 70px;
    width: 70px;
  }
  .packaging .background-packaging .hole .arrow {
    opacity: 0.6;
    background: url("../../assets/arrow.svg");
    background-size: cover;
  }
  .packaging .background-packaging .hole .left-arrow.arrow {
    transform: translateX(100%) rotate(180deg);
  }
  .packaging .background-packaging .left {
    transform: translate(-50%, -50%);
  }
  .packaging .background-packaging .right {
    transform: translate(50%, -50%);
  }
}
.packaging .left {
  border-radius: 10px;
  left: 0;
}
.packaging .right {
  border-radius: 0 10px 10px 0;
  right: 0;
}
.packaging .zeniq-logo-packaging {
  user-select: none;
  display: none;
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
@media (min-width: 800px) {
  .packaging .zeniq-logo-packaging {
    display: flex;
  }
}
.packaging .zeniq-logo-packaging img {
  height: 200px;
}

.slideOutLeft {
  animation: slideOutLeft 2s ease-in-out forwards;
}

.left.slideOutRight {
  animation: slideOutRight 2s ease-in-out forwards;
}

.right.slideOutRight {
  animation: slideOutLeft 2s ease-in-out forwards;
}

@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200vw);
  }
}
@keyframes slideOutRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(200vw);
  }
}
@media (min-width: 800px) {
  .login {
    left: 0;
  }
}

@media (min-width: 800px) and (max-width: 1200px) {
  .login.extend {
    left: -500px !important;
    width: 800px !important;
  }
}
@media (min-width: 1200px) {
  .login.extend {
    left: -700px !important;
    width: 800px !important;
  }
}

@media (min-width: 800px) {
  .register {
    display: flex;
    justify-content: flex-end !important;
    align-items: center;
    right: 0;
  }
}

@media (min-width: 800px) and (max-width: 1200px) {
  .register.extend {
    right: -500px !important;
    width: 800px !important;
  }
}
@media (min-width: 1200px) {
  .register.extend {
    right: -700px !important;
    width: 800px !important;
  }
}

@media (max-width: 800px) {
  .mobile-show {
    display: flex !important;
  }
}

@media (max-width: 800px) {
  .mobile-hide {
    display: none !important;
  }
}

@media (min-width: 800px) {
  .wiggle-left {
    animation: linear 1.5s wiggleLeft infinite;
  }
}

@media (min-width: 800px) {
  .wiggle-right {
    animation: linear 1.5s wiggleRight infinite;
  }
}

@media (min-width: 800px) {
  .left-packaging {
    transform: translateX(500px);
  }
}

@keyframes wiggle {
  0% {
    left: 0;
  }
  25% {
    left: 10px;
  }
  50% {
    left: 0;
  }
  75% {
    left: -10px;
  }
  100% {
    left: 0;
  }
}
@keyframes wiggleLeft {
  0% {
    left: 0;
  }
  25% {
    left: 30px;
  }
  50% {
    left: 0;
  }
  75% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@keyframes wiggleRight {
  0% {
    right: 0;
  }
  25% {
    right: -30px;
  }
  50% {
    right: 0;
  }
  75% {
    right: 30px;
  }
  100% {
    right: 0;
  }
}
@keyframes flyIn {
  0% {
    transform: scale(1) translateY(-200vh);
  }
  80% {
    transform: scale(1) translateY(0);
  }
  100% {
    transform: scale(0.7) translateY(0);
  }
}

