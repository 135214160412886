.App {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (max-height: 900px) {
  .App {
    justify-content: flex-start;
  }
}
.App .background {
  z-index: -1;
  position: absolute;
  background-image: url("../assets/bg-white.png");
  background-size: cover;
  inset: 0 0 0 0;
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.column {
  flex-direction: column;
}

@font-face {
  font-family: Credit;
  src: url("../assets/kredit.ttf");
}

