.information {
  position: absolute;
  top: 120px;
  left: 150px;
  font-size: 35px;
  div {
    div:nth-child(1) {
      font-weight: lighter;
    }
    div:nth-child(2) {
      font-size: 30px;
      margin-bottom: 20px;
      min-height: 30px;
    }
  }
}