.App {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-height: 900px) {
    justify-content: flex-start;
  }

  .background {
    z-index: -1;
    position: absolute;
    //background-image: url("../../assets/bg-black.png");
    background-image: url("../assets/bg-white.png");
    background-size: cover;
    //background-position: 300px 0;
    inset: 0 0 0 0;
  }

}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.column {
  flex-direction: column;
}

@font-face {
  font-family: Credit;
  src: url('../assets/kredit.ttf');
}