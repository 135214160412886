
.qr-container {
  position: absolute;
  top: 15%;
  left: 27%;
  height: 300px;
  width: 300px;
  padding: 20px;
  background: white;
  img {
    height: 100%;
    width: 100%;
  }
}