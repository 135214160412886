.navbar {
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px;
  div {
    margin-bottom: 30px;
    cursor: pointer;
    height: 60px;
    width: 100%;
    transition: 0.3s ease-in-out;
    img {
      width: 100%;
      height: 100%;
    }
    //&:hover {
    //  transform: scale(1.05);
    //}
  }
  .logout {
    transform: scale(1.1) translate(5px, -5px);
  }
}