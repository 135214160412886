.card {
  transition: all ease-in-out 0.8s;
  --scale: 1;
  position: relative;
  height: 900px;
  width: calc(900px / 1.6);
  background: #000000;
  //overflow: hidden;
  border-radius: 35px;
  color: white;
  font-size: 28px;
  font-family: Credit, serif;
  font-weight: bolder;
  transform: scale(var(--scale));
  @media (max-width: 700px) {
    --scale: 0.9;
  }
  @media (max-width: 600px) {
    --scale: 0.8;
  }
  @media (max-width: 500px) {
    --scale: 0.7;
  }
  @media (max-width: 450px) {
    --scale: 0.6;
  }
  @media (max-height: 900px) {
    margin-top: 20px;
  }

  .balance {
    text-align: right;
    position: absolute;
    top: 20px;
    right: 20px;
    div:nth-child(1) {
      font-weight: lighter;
    }
    div:nth-child(2) {
      font-size: 30px;
    }
  }


  .logo-container {
    user-select: none;
    pointer-events: none;
    margin-bottom: 20px;
    width: 100%;
    height: 110px;
    bottom: 0;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .zeniq-logo {
      margin: 20px;
      height: 100%;
      img {
        height: 100%;
      }
    }

    .maestro-logo {
      display: flex;
      justify-content: flex-end;
      padding-right: 30px;
      height: 100%;
      flex-grow: 1;
      img {
        width: 100%;
        transform: scale(1.3);
      }
    }
  }
}

.transactions-card {
  @media (max-width: 1400px) {
    //transform: scale(1.8) rotate(-90deg);
  }
  @media (min-width: 1650px) {
    transform: scale(1.8) rotate(-90deg);
    .shadow {
      opacity: 0;
      position: absolute;
      background: rgba(43, 42, 42, 0.6);
      bottom: 50%;
      right: 50px;
      width: 170%;
      height:40px;
      border-radius:50%;
      filter: blur(20px);
      transform: translateX(-5%) rotate(90deg);
      z-index: 2;
      animation: fadeInAgain 1s 1s forwards;
    }
  }
}
.initial-card {
  animation: slideIn 1s ease-in-out;
}
.home-card {
  transform: scale(var(--scale)) rotate(0deg);
}
.shadow {
  opacity: 0;
  position: absolute;
  background: rgba(43, 42, 42, 0.6);
  bottom: -30px;
  width: 110%;
  height:40px;
  border-radius:50%;
  filter: blur(20px);
  transform: translateX(-5%);
  animation: fadeIn 1s 1s forwards;
  z-index: 2;
}


@keyframes slideIn {
  0% {
    transform: scale(var(--scale)) translate(0, 150vh);
    //transform: scale(var(--scale)) translate(150vw, 0vw) rotate(-180deg);
  }
  100% {
    transform: scale(var(--scale)) translate(0);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInAgain {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fly-out {
  transform: translateY(200vh);
}